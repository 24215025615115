import { initializeApp } from "firebase/app";
import {getAuth, GoogleAuthProvider} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const firebaseConfig2 = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY_2,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_2,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL_2,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_2,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_2,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_2,
  appId: process.env.REACT_APP_FIREBASE_APP_ID_2,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_2,
};

const firebaseConfig3 = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY_3,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_3,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL_3,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_3,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_3,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_3,
  appId: process.env.REACT_APP_FIREBASE_APP_ID_3,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_3,
};

const firebaseConfig4 = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY_4,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_4,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL_4,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_4,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_4,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID_4,
  appId: process.env.REACT_APP_FIREBASE_APP_ID_4,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID_4,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig, "app1");
const app2 = initializeApp(firebaseConfig2, "app2");
const app3 = initializeApp(firebaseConfig3, "app3");
const app4 = initializeApp(firebaseConfig4, "app4");

export const auth = getAuth(app);
export const auth2 = getAuth(app2);
export const auth3 = getAuth(app3);
export const auth4 = getAuth(app4);

export const GoogleProvider = new GoogleAuthProvider();
export const GoogleProvider2 = new GoogleAuthProvider();
export const GoogleProvider3 = new GoogleAuthProvider();
export const GoogleProvider4 = new GoogleAuthProvider();

export const db = getFirestore(app);
export const db2 = getFirestore(app2);
export const db3 = getFirestore(app3);
export const db4 = getFirestore(app4);

export const database = getDatabase(app2);
